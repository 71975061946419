import * as Sentry from '@sentry/nuxt'

Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    dsn: 'https://cfc43388ff6a8504ec3a9ecf38383d6c@o35054.ingest.us.sentry.io/4507974426296320',

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
})
